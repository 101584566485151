const COOKIE_CONSENT_GROUPS = {
  essentialCookies: 'C0001',
  performanceCookies: 'C0002',
  functionalCookies: 'C0003',
  targetingCookies: 'C0004',
  socialMediaCookies: 'C0005',
} as const;

declare global {
  interface Window {
    app: Record<string, unknown>;
  }
}

type TConsentGroupNames = keyof typeof COOKIE_CONSENT_GROUPS;
type TConsentGroups = Record<TConsentGroupNames, boolean>;

const CONSENT_COOKIE_NAME = 'OptanonConsent';

function getRawConsentCookie(name: string = CONSENT_COOKIE_NAME) {
  const escapedCookies = decodeURIComponent(document.cookie);
  const consentCookie = escapedCookies.split('; ').find((rawCookie) => {
    const [cookieName] = rawCookie.split('=');

    return cookieName === name;
  });

  return consentCookie;
}

const defaultConsentGroups = Object.fromEntries(
  Object.keys(COOKIE_CONSENT_GROUPS).map((consentGroup) => [
    consentGroup,
    false,
  ])
) as TConsentGroups;

type TGetParsedConsentCookieGroupsOptions = {
  skipConsent?: boolean;
};
function getParsedConsentCookieGroups(
  cookieValue: string | undefined = getRawConsentCookie(),
  { skipConsent }: TGetParsedConsentCookieGroupsOptions = {}
) {
  let parsedConsentCookieGroups = {};

  const encodedConsentGroupToConsentGroup = Object.fromEntries(
    Object.entries(COOKIE_CONSENT_GROUPS).map(
      ([encodedConsentGroupName, decodedConsentGroupName]) => [
        decodedConsentGroupName,
        encodedConsentGroupName,
      ]
    )
  );

  try {
    const parsedConsentCookieValue = new URLSearchParams(cookieValue);
    const rawConsentCookieGroups = parsedConsentCookieValue.get('groups');
    const parsedConsentCookieGroupEntries =
      rawConsentCookieGroups &&
      rawConsentCookieGroups.split(',').map((consentGroup) => {
        const [encodedConsentGroupName, consentGroupValue] =
          consentGroup.split(':');

        return [
          encodedConsentGroupName &&
            encodedConsentGroupToConsentGroup[encodedConsentGroupName],
          skipConsent === true ? true : consentGroupValue === '1',
        ];
      });

    parsedConsentCookieGroups = parsedConsentCookieGroupEntries
      ? Object.fromEntries(parsedConsentCookieGroupEntries)
      : null;
  } catch (error) {}

  return {
    ...defaultConsentGroups,
    ...parsedConsentCookieGroups,
  };
}

/**
 * Copied from GitHub to make this library not have a UUID library dependency.
 * This implementation is good enough for the purposes here.
 */
function generateUuid(prefix?: string) {
  return prefix
    ? (
        (Number(prefix) ^ (Math.random() * 16)) >>
        (Number(prefix) / 4)
      ).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, generateUuid);
}

function generateEncodedConsentGroups(consentGroups: TConsentGroups): string {
  return Object.entries(consentGroups)
    .map(([consentGroupName, consentGroupValue]) => {
      const encodedConsentGroup =
        COOKIE_CONSENT_GROUPS[consentGroupName as TConsentGroupNames];

      if (!encodedConsentGroup) {
        return null;
      }

      return `${encodedConsentGroup}:${(consentGroupValue && '1') || '0'}`;
    })
    .filter(Boolean)
    .join(',');
}

function generateConsentCookie(consentGroups: Partial<TConsentGroups>) {
  const existingConsentGroups = getParsedConsentCookieGroups();

  const encodedConsentGroups = generateEncodedConsentGroups({
    ...existingConsentGroups,
    ...consentGroups,
  });

  const consentValues = {
    isGpcEnabled: '0',
    datestamp: new Date().toString(),
    version: '202209.1.0',
    isIABGlobal: 'false',
    hosts: '',
    consentId: generateUuid(),
    interactionCount: '1',
    landingPath: 'NotLandingPage',
    groups: encodedConsentGroups,
    AwaitingReconsent: 'false',
  };
  const consentCookieValue = new URLSearchParams(consentValues);

  return consentCookieValue.toString();
}

/**
 * The `window.app.cookieConsentDomain` can be passed but cannot be an empty string.
 *
 * By default `.commercetools.com` is used.
 */
function getConsentCookieDomain() {
  let consentCookieDomain;

  if (
    window.app &&
    window.app.cookieConsentDomain &&
    (window.app.cookieConsentDomain as string).length > 0
  ) {
    consentCookieDomain = window.app.cookieConsentDomain;
  } else {
    consentCookieDomain = '.commercetools.com';
  }

  return consentCookieDomain;
}

function setConsentCookie(
  consentGroups: Partial<TConsentGroups>,
  domain?: string
) {
  const expiresAt = new Date();
  expiresAt.setFullYear(expiresAt.getFullYear() + 11);

  let consentCookieDomain;

  /**
   * The `domain` argument takes precedence if passed.
   */
  if (domain && domain.length > 0) {
    consentCookieDomain = domain;
  } else {
    consentCookieDomain = getConsentCookieDomain();
  }

  document.cookie = `${CONSENT_COOKIE_NAME}=${generateConsentCookie(
    consentGroups
  )}; domain=${consentCookieDomain}; expires=${expiresAt.toUTCString()}; SameSite=Lax; path=/; `;
}

/**
 * Deletes the consent cookie from the browser by setting the cookie's expiration date to a past date.
 */
function deleteConsentCookie() {
  const beginningOfTime = 'Thu, 01 Jan 1970 00:00:00 GMT';
  const cookieConsentDomain = getConsentCookieDomain();

  document.cookie = `${CONSENT_COOKIE_NAME}=; expires=${beginningOfTime}; path=/; domain=${cookieConsentDomain}`;
}

/**
 * Checks and potentially revokes the consent cookie based on its date stamp.
 *
 * If the cookie's date stamp is older than the revocation date, it deletes the cookie.
 */
function unsetConsentCookie(revocationDate: Date) {
  const rawConsentCookie = getRawConsentCookie();
  const parsedConsentCookieValue = new URLSearchParams(rawConsentCookie);
  const cookieDateStamp = parsedConsentCookieValue.get('datestamp');

  if (cookieDateStamp) {
    const cookieDate = new Date(cookieDateStamp!);

    if (cookieDate < revocationDate) {
      deleteConsentCookie();
    }
  }
}

export {
  type TConsentGroups,
  CONSENT_COOKIE_NAME,
  getRawConsentCookie,
  getParsedConsentCookieGroups,
  setConsentCookie,
  unsetConsentCookie,
  generateConsentCookie,
  COOKIE_CONSENT_GROUPS,
};
