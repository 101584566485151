import ReactDOM from 'react-dom';
import { getParsedConsentCookieGroups } from '@commercetools-frontend/cookie-consent/core';
import { initialize as initializeFullStory } from '@commercetools-frontend/fullstory';
import EntryPoint from './components/entry-point';

const consentGroups = getParsedConsentCookieGroups();
const hasGivenPerformanceCookieConsent = Boolean(
  consentGroups.performanceCookies
);

if (hasGivenPerformanceCookieConsent) {
  initializeFullStory();
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<EntryPoint />, document.getElementById('app'));
