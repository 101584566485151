/*
 * This file contains a list of all feature toggles used within
 * this application. Each feature toggle should be used as a constant
 * from this file.
 *
 * Applications use short-lived and long-lived feature toggles.
 * Short-lived feature toggles are served from LaunchDarkly and have the advantage to
 * allow fine grained targeting and can change an application's behaviour
 * without a deployment.
 * Long-lived feature toggles are served from the MC API GraphQL and allow
 * targeting on an environment and project basis.
 * Eventually every short-lived feature toggle should be removed or become a long-lived
 * feature toggle if needed
 *
 * The default values would be used whenever feature toggles have not been
 * fetched from LaunchDarkly or our GraphQL API (without default values flopflip will default
 * them to off until fetched).
 */

import {
  SHARED_DEFAULT_SHORT_LIVED_FLAGS,
  SHARED_DEFAULT_LONG_LIVED_FLAGS,
} from '@commercetools-local/constants/src/feature-toggles';

// Short-running features

// Long-running feature configurations
export const ENABLE_SIGN_UP = 'enableSignUp';
export const ENABLE_WORKSPACES_UI = 'enableWorkspacesUi';
export const ENABLE_GLOBAL_IDENTITY = 'enableGlobalIdentity';

export const DEFAULT_SHORT_LIVED_FLAGS = {
  ...SHARED_DEFAULT_SHORT_LIVED_FLAGS,
};
export const DEFAULT_LONG_LIVED_FLAGS = {
  ...SHARED_DEFAULT_LONG_LIVED_FLAGS,
  [ENABLE_WORKSPACES_UI]: {
    value: false,
  },
  [ENABLE_SIGN_UP]: {
    value: true,
  },
  [ENABLE_GLOBAL_IDENTITY]: {
    value: false,
  },
};

export const ALL_DEFAULT_FLAGS = {
  ...DEFAULT_SHORT_LIVED_FLAGS,
  ...DEFAULT_LONG_LIVED_FLAGS,
};
