import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors';

type TAdditionalEnvironmentValues = { skipCookieConsent: boolean };
type TSelectedEnvironmentValues = boolean | undefined;

function useSkipCookieConsent() {
  const skipCookieConsent = useApplicationContext<
    TSelectedEnvironmentValues,
    TAdditionalEnvironmentValues
  >((context) => context.environment.skipCookieConsent);

  return Boolean(skipCookieConsent);
}

export { useSkipCookieConsent };
