import { AsyncLocaleData } from '@commercetools-frontend/i18n';
import applicationMessages from '../../messages';
import ConfigureIntlProvider from './configure-intl-provider';
import getSupportedBrowserLocale from './get-supported-browser-locale';

// In case the user is authenticated, the AppShell Provider
// renders the children without the Intl Provider.
// This is usually ok because the auth app simply redirects
// to the welcome app since the user is authenticated.
// However, certain pages should always be rendered, no matter
// if the user is authenticated or not.
// To amend that, we need to manually wrap the login page
// with the Intl Provider, but only if the user is already authenticated.
type WrapWithIntlProviderIfNeededProps = {
  isAuthenticated: boolean;
  children: React.ReactNode;
};
const WrapWithIntlProviderIfNeeded = (
  props: WrapWithIntlProviderIfNeededProps
) => {
  if (!props.isAuthenticated) {
    return props.children;
  }
  const browserLocale = getSupportedBrowserLocale();
  return (
    <AsyncLocaleData
      locale={browserLocale}
      applicationMessages={applicationMessages}
    >
      {({ locale, messages }) => (
        <ConfigureIntlProvider locale={locale} messages={messages}>
          {props.children}
        </ConfigureIntlProvider>
      )}
    </AsyncLocaleData>
  );
};

export default WrapWithIntlProviderIfNeeded;
