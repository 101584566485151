import { KeyObject } from 'crypto';
import { useEffect } from 'react';
import {
  FullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser';
import {
  type TApplicationContext,
  useApplicationContext,
} from '@commercetools-frontend/application-shell-connectors';

type TTrackingArgs = {
  disable?: boolean;
  additionalUserVars?: KeyObject;
};

type TAdditionalEnvironmentValues = {
  cloudEnvironment: string;
};
type TSelectedEnvironmentValues = {
  user: TApplicationContext<TAdditionalEnvironmentValues>['user'];
  project: TApplicationContext<TAdditionalEnvironmentValues>['project'];
  cloudEnvironment: string;
  businessRole?: string;
  organizationId?: string;
  isProductionProject?: boolean;
};
const defaultTrackingArgs: TTrackingArgs = {
  disable: false,
  additionalUserVars: undefined,
};

function useTrackingEffect({
  disable,
  additionalUserVars,
}: TTrackingArgs = defaultTrackingArgs) {
  const {
    project,
    user,
    cloudEnvironment,
    organizationId,
    isProductionProject,
  } = useApplicationContext<
    TSelectedEnvironmentValues,
    TAdditionalEnvironmentValues
  >((context) => ({
    user: context.user,
    project: context.project,
    cloudEnvironment: context.environment.cloudEnvironment,
    organizationId: context.project?.ownerId,
    isProductionProject: context.project?.isProductionProject,
  }));

  useEffect(() => {
    function getUserRole(): string {
      switch (project?.isUserAdminOfCurrentProject) {
        case true:
          return 'admin';
        case false:
          return 'regular';
        default:
          return 'unknown';
      }
    }

    // It's not safe to invoke any FullStory SDK methods.
    if (!isFullStoryInitialized() || disable) {
      return;
    }

    if (!user?.id) {
      FullStory('setIdentity', { anonymous: true });
    } else {
      FullStory('setIdentity', {
        uid: user.id,
        properties: {
          environment: cloudEnvironment,
          projectKey: project?.key,
          isProductionProject,
          organizationId,
          userBusinessRole: user.businessRole,
          userLocale: user.locale,
          userRole: getUserRole(),
          ...additionalUserVars,
        },
      });
    }
  }, [
    disable,
    additionalUserVars,
    cloudEnvironment,
    project?.key,
    user?.businessRole,
    user?.id,
    user?.locale,
    isProductionProject,
    organizationId,
    project?.isUserAdminOfCurrentProject,
  ]);

  return null;
}

export { useTrackingEffect };
