import { defineMessages } from 'react-intl';

export const modalMessages = defineMessages({
  title: {
    id: 'CookieConsentModal.title',
    description: 'Confirmation dialog title',
    defaultMessage: 'Privacy Preference Center',
  },
  primaryButtonLabel: {
    id: 'CookieConsentModal.primaryButtonLabel',
    description: 'Primary button text',
    defaultMessage: 'Accept all cookies',
  },
  secondaryButtonLabel: {
    id: 'CookieConsentModal.secondaryButtonLabel',
    description: 'Secondary button text',
    defaultMessage: 'Confirm my choices',
  },
  mainDescription: {
    id: 'CookieConsentModal.primary.mainDescription',
    description: 'Main body copy',
    defaultMessage:
      'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
  },
  privacyPolicyLink: {
    id: 'CookieConsentModal.primary.privacyPolicyLink',
    description: 'Link to privacy policy',
    defaultMessage: 'More information',
  },
  necessaryCookiesHeader: {
    id: 'CookieConsentModal.primary.necessaryCookiesHeader',
    description: 'Header for first collapsible content block',
    defaultMessage: 'Strictly necessary cookies',
  },
  functionalCookiesHeader: {
    id: 'CookieConsentModal.primary.functionalCookiesHeader',
    description: 'Header for first collapsible content block',
    defaultMessage: 'Functional cookies',
  },
  functionalCookiesDescription: {
    id: 'CookieConsentModal.primary.functionalCookiesDescription',
    description: 'Description of content from first collapsible header',
    defaultMessage:
      'These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
  },
  performanceCookiesHeader: {
    id: 'CookieConsentModal.primary.performanceCookiesHeader',
    description: 'Header for second collapsible content block',
    defaultMessage: 'Performance cookies',
  },
  performanceCookiesDescription: {
    id: 'CookieConsentModal.primary.performanceCookiesDescription',
    description: 'Description of content from second collapsible header',
    defaultMessage:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
  },
  cookieInformationLink: {
    id: 'CookieConsentModal.primary.cookieInformationLink',
    description: 'Link to additional cookie information',
    defaultMessage: 'Cookies details',
  },
  navigateBackLink: {
    id: 'CookieConsentModal.primary.navigateBackLink',
    description: 'Link to go back to primary view',
    defaultMessage: 'Back to Privacy Preference Center',
  },
});

export const cookieDetailLabels = defineMessages({
  nameLabel: {
    id: 'CookieConsentModal.cookieDetails.nameLabel',
    description: 'Name - category identifier',
    defaultMessage: 'Name',
  },
  hostLabel: {
    id: 'CookieConsentModal.cookieDetails.hostLabel',
    description: 'Host - category identifier',
    defaultMessage: 'Host',
  },
  durationLabel: {
    id: 'CookieConsentModal.cookieDetails.durationLabel',
    description: 'Duration - category identifier',
    defaultMessage: 'Duration',
  },
  typeLabel: {
    id: 'CookieConsentModal.cookieDetails.typeLabel',
    description: 'Type - category identifier',
    defaultMessage: 'Type',
  },
  categoryLabel: {
    id: 'CookieConsentModal.cookieDetails.categoryLabel',
    description: 'Category - category identifier',
    defaultMessage: 'Category',
  },
  descriptionLabel: {
    id: 'CookieConsentModal.cookieDetails.descriptionLabel',
    description: 'Description - category identifier',
    defaultMessage: 'Description',
  },
});

export const cookieDetailValues = defineMessages({
  duration9Months: {
    id: 'CookieConsentModal.cookieDetails.duration9Months',
    description: 'Length of time',
    defaultMessage: '9 Months',
  },
  duration1Week: {
    id: 'CookieConsentModal.cookieDetails.duration1Week',
    description: 'Length of time',
    defaultMessage: '1 Week',
  },
  durationSession: {
    id: 'CookieConsentModal.cookieDetails.durationSession',
    description: 'Length of time for cookie to expire in the browser',
    defaultMessage: 'Session',
  },
  duration1Year: {
    id: 'CookieConsentModal.cookieDetails.duration1Year',
    description: 'Length of time',
    defaultMessage: '1 Year',
  },
  duration1Day: {
    id: 'CookieConsentModal.cookieDetails.duration1Day',
    description: 'Length of time',
    defaultMessage: '1 Day',
  },
  typeFirstParty: {
    id: 'CookieConsentModal.cookieDetails.typeFirstParty',
    description: 'Type of cookie',
    defaultMessage: 'First party',
  },
  categoryStrictlyNecessary: {
    id: 'CookieConsentModal.cookieDetails.categoryStrictlyNecessary',
    description: 'Type of cookie',
    defaultMessage: 'Strictly Necessary Cookies',
  },
  categoryFunctional: {
    id: 'CookieConsentModal.cookieDetails.categoryFunctional',
    description: 'Type of cookie',
    defaultMessage: 'Functional Cookies',
  },
  categoryPerformance: {
    id: 'CookieConsentModal.cookieDetails.categoryPerformance',
    description: 'Type of cookie',
    defaultMessage: 'Performance Cookies',
  },
});

export const cookieDetailDescriptions = defineMessages({
  functionalCookieDescription1: {
    id: 'CookieConsentModal.cookieDetails.functionalCookieDescription1',
    description: 'Description of cookie',
    defaultMessage:
      'Set by UserGuiding (Merchant Center). As per UserGuiding Cookie Policy: This cookie contains pages the user has seen. Its purpose is to improve the user guidance experience, like showing guides only once for each user, segmenting based on page view history, etc.',
  },
  performanceCookieDescription1: {
    id: 'CookieConsentModal.cookieDetails.performanceCookieDescription1',
    description: 'Description of cookie',
    defaultMessage:
      'Support multi-tab playback, provides a unique ID to each tab. Note that this is technically not a cookie, but a form of sessionStorage: https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage Set by FullStory within the commercetools Merchant Center.',
  },
  performanceCookieDescription2: {
    id: 'CookieConsentModal.cookieDetails.performanceCookieDescription2',
    description: 'Description of cookie',
    defaultMessage:
      'Stores the consent state for this device. For more on consent state see: https://help.fullstory.com/hc/en-us/articles/360020623254-FS-consent-Capture-elements-with-consent Set by FullStory within the commercetools Merchant Center.',
  },
  performanceCookieDescription3: {
    id: 'CookieConsentModal.cookieDetails.performanceCookieDescription3',
    description: 'Description of cookie',
    defaultMessage:
      'Captures the timestamp of the last user action. It is used to assist with the FullStory session lifecycle, ensuring user activity extends the session. See "What defines a session in FullStory?" for more info on the session lifecycle: https://help.fullstory.com/hc/en-us/articles/360020624614-What-defines-a-session-in-FullStory- Set by FullStory within the commercetools Merchant Center.',
  },
  performanceCookieDescription4: {
    id: 'CookieConsentModal.cookieDetails.performanceCookieDescription4',
    description: 'Description of cookie',
    defaultMessage:
      "The 'fs_uid' cookie can be thought of as the capture cookie. When an end-user visits a customer's site, that cookie is used to track the user across sessions and pages. The same user may visit a site multiple times and may navigate to many pages within a single session. This cookie ensures that all captured session traffic is associated with one user. A session cannot be captured without this cookie and the users anonymized visit will not be logged. Set by FullStory within the commercetools Merchant Center.",
  },
  performanceCookieDescription5: {
    id: 'CookieConsentModal.cookieDetails.performanceCookieDescription5',
    description: 'Description of cookie',
    defaultMessage:
      "Set by UserGuiding (Merchant Center). As per UserGuiding Cookie Policy: This cookie is for storing in the current browser a long living random unique id, which is created at first load of the UserGuiding snippet. This ID is created randomly based on the timestamp of the first visit and does not relate to the user’s personal information including IP. The stated purpose of the cookie is to track UserGuiding users' monthly active user quota, storing users' usage history to UserGuiding databases, etc.",
  },
});
