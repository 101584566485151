import { init as initializeFullStory } from '@fullstory/browser';

declare global {
  interface Window {
    app: Record<string, unknown>;
  }
}

function assertValidFullstoryOrganizationId(
  fullstoryOrganizationId: unknown
): asserts fullstoryOrganizationId is string {
  if (typeof fullstoryOrganizationId !== 'string') {
    throw new Error('A valid FullStory organization id must be a string.');
  }

  if (fullstoryOrganizationId.length <= 0) {
    throw new Error('A FullStory organization id can not be an empty string.');
  }
}

function initialize(
  fullstoryOrganizationId = window.app.fullstoryOrganizationId
) {
  try {
    try {
      assertValidFullstoryOrganizationId(fullstoryOrganizationId);
    } catch (error) {
      console.warn(
        '[@commercetools-frontend/fullstory]: No valid `fullstoryOrganizationId` passed. Not initializing FullStory.'
      );

      return;
    }

    initializeFullStory({
      orgId: fullstoryOrganizationId,
      devMode: process.env.NODE_ENV !== 'production',
    });
  } catch (error) {
    console.warn(
      '[@commercetools-frontend/fullstory]: Failed to initialize FullStory due to an error:',
      error
    );
  }
}

export { initialize };
