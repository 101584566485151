import type { IntlShape } from 'react-intl';
import { COOKIE_CONSENT_LINKS } from '../../../constants/links';
import {
  cookieDetailDescriptions,
  cookieDetailLabels,
  cookieDetailValues,
} from './messages';

type Detail = { label: string; value: string };
type Link = Detail & { to: string };

export type CookieDetails = {
  name: Link;
  host: Detail;
  duration: Detail;
  type: Detail;
  category: Detail;
  description: Detail;
};

const HOSTNAME = 'commercetools.com';
const createCookieLink = (cookieName: string) =>
  `https://cookiepedia.co.uk/cookies/${cookieName}`;

export const createFunctionalCookieDetails = (
  intl: IntlShape
): Array<CookieDetails> => [
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: '__ug__',
      to: COOKIE_CONSENT_LINKS.USER_GUIDING_COOKIE_POLICY,
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.durationSession),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.typeFirstParty),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryFunctional),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.functionalCookieDescription1
      ),
    },
  },
];

export const createPerformanceCookieDetails = (
  intl: IntlShape
): Array<CookieDetails> => [
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: '_fs_tab_id',
      to: createCookieLink('_fs_tab_id'),
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.durationSession),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.duration9Months),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryPerformance),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.performanceCookieDescription1
      ),
    },
  },
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: 'fs_cid',
      to: createCookieLink('fs_cid'),
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.duration1Year),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.typeFirstParty),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryPerformance),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.performanceCookieDescription2
      ),
    },
  },
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: 'fs_lua',
      to: createCookieLink('fs_lua'),
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.duration1Day),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.typeFirstParty),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryPerformance),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.performanceCookieDescription3
      ),
    },
  },
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: 'fs_uid',
      to: createCookieLink('fs_uid'),
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.duration9Months),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.typeFirstParty),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryPerformance),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.performanceCookieDescription4
      ),
    },
  },
  {
    name: {
      label: intl.formatMessage(cookieDetailLabels.nameLabel),
      value: '__UGS__uid',
      to: COOKIE_CONSENT_LINKS.USER_GUIDING_COOKIE_POLICY,
    },
    host: {
      label: intl.formatMessage(cookieDetailLabels.hostLabel),
      value: HOSTNAME,
    },
    duration: {
      label: intl.formatMessage(cookieDetailLabels.durationLabel),
      value: intl.formatMessage(cookieDetailValues.durationSession),
    },
    type: {
      label: intl.formatMessage(cookieDetailLabels.typeLabel),
      value: intl.formatMessage(cookieDetailValues.typeFirstParty),
    },
    category: {
      label: intl.formatMessage(cookieDetailLabels.categoryLabel),
      value: intl.formatMessage(cookieDetailValues.categoryPerformance),
    },
    description: {
      label: intl.formatMessage(cookieDetailLabels.descriptionLabel),
      value: intl.formatMessage(
        cookieDetailDescriptions.performanceCookieDescription5
      ),
    },
  },
];
