export const BUSINESS_ROLE_NOTIFICATION = 'businessRoleNotification';
export const EXCLUDING_PRODUCTS = 'excludingProducts';
export const PRODUCT_TAILORING_FOR_ATTRIBUTES = 'productTailoringForAttributes';

// Long-running feature configurations
export const ENABLE_AUDIT_LOG = 'enableAuditLog';
export const ENABLE_SETTINGS = 'enableSettings';
export const ENABLE_LOGIN_VIA_SSO = 'enableLoginViaSingleSignOn';
export const ENABLE_CUSTOM_APPLICATIONS = 'enableCustomApplications';
export const ENABLE_CUSTOM_VIEWS = 'enableCustomViews';
export const ENABLE_DASHBOARD = 'enableDashboard';
export const ENABLE_CUSTOMERS = 'enableCustomers';
export const ENABLE_ORDERS = 'enableOrders';
export const ENABLE_OPERATIONS = 'enableOperations';
export const ENABLE_SYSTEM_STATUS = 'enableSystemStatus';
export const ENABLE_OPERATIONS_EXPORT = 'enableOperationsExport';
export const ENABLE_OPERATIONS_IMPORT = 'enableOperationsImport';

export const SHARED_DEFAULT_SHORT_LIVED_FLAGS = {
  [BUSINESS_ROLE_NOTIFICATION]: false,
  [EXCLUDING_PRODUCTS]: false,
  [PRODUCT_TAILORING_FOR_ATTRIBUTES]: false,
  [ENABLE_SETTINGS]: {
    value: true,
  },
};

export const SHARED_DEFAULT_LONG_LIVED_FLAGS = {
  [ENABLE_AUDIT_LOG]: {
    value: false,
  },
  [ENABLE_SETTINGS]: {
    value: true,
  },
  [ENABLE_LOGIN_VIA_SSO]: {
    value: true,
  },
  [ENABLE_CUSTOM_APPLICATIONS]: {
    value: true,
  },
  [ENABLE_CUSTOM_VIEWS]: {
    value: true,
  },
  [ENABLE_DASHBOARD]: {
    value: true,
  },
  [ENABLE_CUSTOMERS]: {
    value: true,
  },
  [ENABLE_ORDERS]: {
    value: true,
  },
  [ENABLE_OPERATIONS]: {
    value: true,
  },
  [ENABLE_SYSTEM_STATUS]: {
    value: true,
  },
  [ENABLE_OPERATIONS_EXPORT]: {
    value: false,
  },
  [ENABLE_OPERATIONS_IMPORT]: {
    value: false,
  },
};
