import { defineMessages } from 'react-intl';

export default defineMessages({
  bannerMessage: {
    id: 'CookieConsentBanner.bannerMessage',
    description: 'Text of the cookie consent banner.',
    defaultMessage:
      'This site uses cookies. See our {privacyLink} or manage your {modalLink} here.',
  },
  privacyPolicyLink: {
    id: 'CookieConsentBanner.privacyPolicyLink',
    description: 'Privacy policy link text in the cookie consent banner.',
    defaultMessage: 'privacy policy',
  },
  bannerCookiesModalLink: {
    id: 'CookieConsentBanner.bannerCookiesModalLink',
    description:
      'Cookie settings modal link text in the cookie consent banner.',
    defaultMessage: 'cookies settings',
  },
  buttonLabel: {
    id: 'CookieConsentBanner.buttonLabel',
    description: 'Button text allowing the user to accept all ct cookies',
    defaultMessage: 'Accept all cookies',
  },
});
