import { useMemo } from 'react';
import {
  getRawConsentCookie,
  setConsentCookie,
  getParsedConsentCookieGroups,
  COOKIE_CONSENT_GROUPS,
} from '../../core';

type TConsentOptions = {
  skipConsent?: boolean;
  cookieName?: string;
};
const defaultConsentOptions = {
  skipConsent: false,
  cookieName: 'OptanonConsent',
};
function useCookieConsent(
  consentGroup: keyof typeof COOKIE_CONSENT_GROUPS,
  { skipConsent, cookieName }: TConsentOptions = defaultConsentOptions
) {
  const consentCookieGroups = useMemo(() => {
    const rawConsentCookie = getRawConsentCookie(cookieName);
    const consentCookieGroups = getParsedConsentCookieGroups(rawConsentCookie, {
      skipConsent,
    });

    return consentCookieGroups;
  }, [cookieName, skipConsent]);

  return {
    givenConsent: skipConsent || Boolean(consentCookieGroups[consentGroup]),
    setConsent: setConsentCookie,
  };
}

export { useCookieConsent };
